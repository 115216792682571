import { createEvent, createStore, restore, sample } from "effector";
import { createPostRequest } from "../api";
export const wixProcessingErrorStore = createStore(null);
export const processWixWebhook = createEvent();
export const invalidWixWebhookInput = createEvent();
export const processWixWebhookFx = createPostRequest("utils/processWixWebhook");
sample({
    source: processWixWebhook,
    target: processWixWebhookFx,
});
export const wixProcessingResultStore = restore(processWixWebhookFx, "");
wixProcessingErrorStore.reset(processWixWebhookFx.done);
wixProcessingResultStore.reset(processWixWebhookFx.fail);
sample({
    source: invalidWixWebhookInput,
    target: wixProcessingErrorStore,
});
sample({ source: processWixWebhookFx.failData, target: wixProcessingErrorStore });

import { createStore, forward } from "effector";
import { replaceState, prop } from "../../utils";
import { createGetRequest, createPostRequest } from "../api";
const defaultDashboardData = { knownProducts: [], duplicates: [] };
export const getDashboardData = createGetRequest("dashboard");
export const dashboardDataStore = createStore(defaultDashboardData).on(getDashboardData.doneData, replaceState);
export const knownProductsStore = dashboardDataStore.map(prop("knownProducts"));
export const knownProductCountStore = knownProductsStore.map(prop("length"));
export const duplicatesStore = dashboardDataStore.map(prop("duplicates"));
export const duplicatesCountStore = duplicatesStore.map(prop("length"));
export const syncWixCatalog = createPostRequest("dashboard/sync-wix-catalog");
export const syncSquareCatalog = createPostRequest("dashboard/sync-square-catalog");
forward({
    from: [syncWixCatalog.done, syncSquareCatalog.done],
    to: getDashboardData,
});

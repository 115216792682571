<script lang="ts" context="module">
  import { onMount } from "svelte";
  import { combine, createStore } from "effector";
  import {
    getDashboardData,
    knownProductsStore,
    knownProductCountStore,
    duplicatesStore,
    duplicatesCountStore,
    syncWixCatalog,
    syncSquareCatalog,
  } from "./features/Dashboard";

  import type { ProcessedItem } from "./features/Dashboard";

  import Statistic from "./dashboard/Statistic.svelte";
  import Placeholder from "./Placeholder.svelte";
  /* import VariantsFilter from "./VariantsFilter.svelte"; */
  import { asSvelteStore } from "./utils";
  /* import Modal from "./Modal.svelte"; */

  const areMatchedHidden = asSvelteStore(createStore(true));

  const getDashboardDataPending = getDashboardData.pending;
  const syncWixCatalogPending = syncWixCatalog.pending;
  const syncSquareCatalogPending = syncSquareCatalog.pending;

  const negativeMapper = (x: number) => (x > 0 ? "red" : "green");

  const wixVariantChoicesMap$ = knownProductsStore.map((items) => {
    const choices = items.reduce((acc, { wix_variant_choices }) => {
      if (!wix_variant_choices) {
        return acc;
      }
      const keys = Object.keys(wix_variant_choices);

      keys.forEach((key) => {
        if (!acc[key]) {
          acc[key] = new Set();
        }
        acc[key].add(wix_variant_choices[key]);
      });

      return acc;
    }, {} as Record<string, Set<string>>);
    return Object.entries(choices).reduce((acc, [key, value]) => {
      return { ...acc, [key]: Array.from(value) };
    }, {} as Record<string, string[]>);
  });

  const processedKnownItemsStore = combine(duplicatesStore, knownProductsStore, (duplicates, knownProducts) =>
    knownProducts.map(
      (row): ProcessedItem => ({
        ...row,
        wix_variant_choices: row.wix_variant_choices
          ? Object.entries(row.wix_variant_choices).reduce((acc, [key, value], idx, { length }) => {
              return acc + `${key}: ${value}${idx < length - 1 ? "," : ""}`;
            }, "")
          : undefined,
        is_duplicate: row.sku ? duplicates.includes(row.sku) : false,
        name_mismatch:
          !!row.wix_product_name && !!row.square_product_name && row.wix_product_name !== row.square_product_name,
      })
    )
  );
  const orphanedInWixStore = knownProductsStore.map((products) => products.filter((row) => !row.wix_product_id).length);
  const orphanedInSquareStore = knownProductsStore.map(
    (products) => products.filter((row) => !row.square_item_id).length
  );

  const orphanedInWixWarningStore = orphanedInWixStore.map(negativeMapper);
  const orphanedInSquareWarningStore = orphanedInSquareStore.map(negativeMapper);
  const skuWarningStore = duplicatesCountStore.map(negativeMapper);

  const filteredProductsStore = combine({ processedKnownItemsStore, areMatchedHidden }, (x) => {
    return x.areMatchedHidden
      ? x.processedKnownItemsStore.filter((i) => !(i.wix_product_id && i.square_item_id))
      : x.processedKnownItemsStore;
  });
</script>

<script lang="ts">
  onMount(() => getDashboardData());
</script>

<svelte:head>
  <title>Dashboard</title>
</svelte:head>

<div class="ui row">
  <div class="sixteen wide column">
    <h1>Dashboard</h1>
    <Placeholder active={$getDashboardDataPending}>
      <div class="ui statistics">
        <Statistic label="Known Wix Items" value={$knownProductCountStore} />
        <Statistic label="Duplicated SKUs" value={$duplicatesCountStore} color={$skuWarningStore} />
        <Statistic label="Missing in Wix" value={$orphanedInWixStore} color={$orphanedInWixWarningStore} />
        <Statistic label="Missing in Square" value={$orphanedInSquareStore} color={$orphanedInSquareWarningStore} />
      </div>
    </Placeholder>
  </div>
</div>
<div class="ui row">
  <div class="sixteen wide column">
    <h2>Products</h2>
    <!--<VariantsFilter choices={$wixVariantChoicesMap$} />-->
    <div class="ui toggle checkbox">
      <input type="checkbox" name="showMatched" bind:checked={$areMatchedHidden} />
      <label for="showMatched">Show Matched Products</label>
    </div>
    <Placeholder active={$getDashboardDataPending} lines={10}>
      <table class="ui celled table">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Wix name</th>
            <th>Square name</th>
          </tr>
        </thead>
        <tbody>
          {#each $filteredProductsStore as row}
            <tr class:positive={!row.is_duplicate && row.wix_product_id && row.square_item_id}>
              <td class:negative={row.is_duplicate}>{row.sku || "-"}</td>
              <td class:negative={!row.wix_product_id} class:warning={row.name_mismatch}>
                {#if row.wix_product_id}
                  {row.wix_product_name}
                  {#if row.wix_variant_choices}({row.wix_variant_choices}){/if}
                {:else}-{/if}
              </td>
              <td class:negative={!row.square_item_id} class:warning={row.name_mismatch}>
                {#if row.square_item_id}
                  {row.square_product_name}
                  {#if row.square_variation_name}({row.square_variation_name}){/if}
                {:else}-{/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </Placeholder>
  </div>
</div>
<div class="ui row">
  <div class="sixteen wide column">
    <h2>Sync Data</h2>
    <button class="ui primary button" on:click={() => syncWixCatalog()} disabled={$syncWixCatalogPending}
      >Sync Wix Data</button
    >
    <button class="ui primary button" on:click={() => syncSquareCatalog()} disabled={$syncSquareCatalogPending}
      >Sync Square Data</button
    >
  </div>
</div>
<!--
<Modal>
  <div class="ui modal visible active">
    <i class="close icon" />
    <div class="header">Profile Picture</div>
    <div class="image content">
      <div class="ui medium image"><img src="/images/avatar/large/chris.jpg" alt="avatar" /></div>
      <div class="description">
        <div class="ui header">We've auto-chosen a profile image for you.</div>
        <p>
          We've grabbed the following image from the <a href="https://www.gravatar.com" target="_blank">gravatar</a> image
          associated with your registered e-mail address.
        </p>
        <p>Is it okay to use this photo?</p>
      </div>
    </div>
    <div class="actions">
      <div class="ui black deny button">Nope</div>
      <div class="ui positive right labeled icon button">Yep, that's me <i class="checkmark icon" /></div>
    </div>
  </div>
</Modal>
-->

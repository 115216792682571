<script context="module" lang="ts">
  export type PlaceholderType = "lines";
</script>
<script lang="ts">
  export let active: boolean = false;
  export const type: PlaceholderType = "lines";
  export let lines: number = 3;

  $: linesIterable = Array.from({ length: lines });
</script>

{#if active}
  <div class="ui fluid placeholder">
    {#each linesIterable as _}
      <div class="line"/>
    {/each}
  </div>
{:else}
  <slot />
{/if}

import connect from "socket.io-client";
import { createDomain } from "effector";
import { replaceState } from "./utils";
export default class SocketIoDomain {
    constructor(ioOptions) {
        this.domain = createDomain();
        this.updateSocketState = this.domain.createEvent();
        this.socketState = this.domain
            .createStore("offline")
            .on(this.updateSocketState, replaceState);
        this.subscriptions = [];
        this.io = connect(ioOptions);
        this.io.on("connect", () => this.updateSocketState("online"));
        this.io.on("disconnect", () => this.updateSocketState("offline"));
        this.io.on("error", () => this.updateSocketState("error"));
    }
    attachEvent(name) {
        const event = this.domain.createEvent({ name });
        this.subscriptions.push(this.io.on(name, (data) => {
            event(data);
        }));
        return event;
    }
}

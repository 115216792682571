<script type="ts" context="module">
  import { currentPageStore } from "./features/App";
  import type { CurrentPage } from "./features/App";
</script>

<script type="ts">
  export let page: CurrentPage;
</script>

{#if $currentPageStore === page}
  <slot /> 
{/if}

<script lang="ts" context="module">
  import { onMount } from "svelte";
  import {
    locationsStore,
    selectedLocationIdStore,
    getSettingsData,
    hasTokenStore,
    disconnectFromSquare,
    updateSelectedLocation,
  } from "./features/Settings";
  import { asSvelteStore } from "./utils";
  import { instanceStore } from "./features";
  import { combine } from "effector";
  import type { Store } from "effector";
  import Placeholder from "./Placeholder.svelte";

  const getSettingsDataPending = getSettingsData.pending;
</script>

<script lang="ts">
  var popup: Window | null;

  function connectToSquare() {
    popup = window.open(
      `/api/settings/connect-to-square?instance=${$instanceStore}`,
      "connectToSquare",
      "width=600;height=800"
    );
  }

  function handleWindowMessage({ data, origin }: MessageEvent) {
    if (origin !== location.origin) return;
    switch (data) {
      case "connected-to-square": {
        if (popup) {
          popup.close();
        }
      }
    }
  }

  window.addEventListener("message", handleWindowMessage, false);

  onMount(() => {
    getSettingsData();
  });

  const selectedLocationId = asSvelteStore(selectedLocationIdStore);

  function saveLocation() {
    updateSelectedLocation({ locationId: selectedLocationId.getState() });
  }

  const updateSelectedLocationPending = updateSelectedLocation.pending;

  const canSaveStore = combine(
    (selectedLocationId as unknown) as Store<string>,
    selectedLocationIdStore,
    (newValue, oldValue) => newValue !== oldValue
  );
</script>

<svelte:head>
  <title>Settings</title>
</svelte:head>

<div class="row">
  <div class="column">
    <h2 class="ui center aligned icon header"><i class="circular credit card icon" /> Square Integration Settings</h2>
  </div>
</div>

<div class="row">
  <div class="column">
    <Placeholder active={$getSettingsDataPending} lines={5}>
      {#if $hasTokenStore}
        <form class="ui form">
          <div class="field">
            <label for="locationId">Fulfillment Location</label>
            <select id="locationId" name="locationId" bind:value={$selectedLocationId}>
              <option value="">-- Select Fulfillment Location --</option>
              {#each $locationsStore as location}
                <option value={location.id}>{location.name}</option>
              {/each}
            </select>
          </div>
          <button
            class="ui primary button"
            on:click|preventDefault={() => saveLocation()}
            disabled={$updateSelectedLocationPending || !$canSaveStore}>Save</button>
          <button class="ui negative basic button" on:click|preventDefault={() => disconnectFromSquare()}>Disconnect
            from Square</button>
        </form>
      {:else}
        <form class="ui form">
          <button class="ui primary button" on:click|preventDefault={() => connectToSquare()}>Connect to Square</button>
        </form>
      {/if}
    </Placeholder>
  </div>
</div>

<script lang="ts" context="module">
  import {
    invalidWixWebhookInput,
    processWixWebhook,
    wixProcessingErrorStore,
    wixProcessingResultStore,
  } from "./features/Utilities";
  import { asSvelteStore } from "./utils";

  const wixProcessingError = asSvelteStore(wixProcessingErrorStore);
  const wixProcessingResult = asSvelteStore(wixProcessingResultStore);
</script>

<script lang="ts">
  function processPayload(e: Event) {
    const form = e.target as HTMLFormElement;

    const inputElement = form["wixWebhookPayload"];

    try {
      const data = JSON.parse(inputElement.value);

      processWixWebhook(data);
    } catch (ex) {
      invalidWixWebhookInput(ex as Error);
    }
  }
</script>

<svelte:head>
  <title>Utilities</title>
</svelte:head>

<div class="row">
  <div class="column">
    <h1>Utilities</h1>

    <form class="ui form" on:submit|preventDefault={(e) => processPayload(e)}>
      <div class="field">
        <label for="wixWebhookPayload">Wix Webhook Payload</label>
        <textarea name="wixWebhookPayload" id="wixWebhookPayload" />
      </div>

      <button class="ui submit button">Process</button>
    </form>

    {#if $wixProcessingError}
        Error!  {$wixProcessingError.message}
    {/if}
  </div>
</div>

<script context="module" lang="ts">
  export type StatisticOrientation = "vertical" | "horizontal";
</script>

<script lang="ts">
  export let value: any;
  export let label: string;
  export let icon: string | undefined = undefined;
  export let orientation: StatisticOrientation = "vertical";
  export let color: string = "";
  export let standalone: boolean = false;
</script>

<div class="{ standalone ? 'ui' : '' } {orientation} {color} statistic">
  <div class="value">
    {#if icon}<i class="{icon} icon" />{/if}
    {value}
  </div>
  <div class="label">{label}</div>
</div>

import { attach, forward } from "effector";
import { appDomain } from "../appDomain";
import { replaceState, payloadProp } from "../../utils";
export class ApiError extends Error {
    constructor(statusCode, resource, message) {
        super(message);
        this.statusCode = statusCode;
        this.resource = resource;
        this.message = message;
    }
}
export const updateInstance = appDomain.createEvent();
export const instanceStore = appDomain.createStore("").on(updateInstance, replaceState);
const backendRequest = appDomain.createEffect(async ({ instance, resource, data }) => {
    if (!instance) {
        throw new Error("Instance undefined");
    }
    const response = await fetch(`/api/${resource}`, {
        method: data ? "POST" : "GET",
        headers: {
            "X-Instance": instance,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new ApiError(response.status, resource, response.statusText);
    }
    return await response.json();
});
// export const apiErrors = appDomain
//   .createStore<ApiError[]>([])
//   .on(backendRequest().fail, (state, payload) => [...state, payload.error as ApiError]);
const authorizedRequest = attach({
    source: instanceStore,
    effect: backendRequest,
    mapParams: ({ resource, data }, instance) => ({
        instance,
        resource,
        data,
    }),
});
export const createGetRequest = (resource) => attach({
    effect: authorizedRequest,
    mapParams: () => ({ resource }),
});
export const createPostRequest = (resource) => attach({
    effect: authorizedRequest,
    mapParams: (data = {}) => ({ data, resource }),
});
const validateInstanceFx = createGetRequest("validate-instance");
export const isInstanceValidatingStore = validateInstanceFx.pending;
forward({ from: instanceStore, to: validateInstanceFx });
export const isInstanceValidStore = appDomain.createStore(false).on(validateInstanceFx.doneData, (_, payload) => {
    return payload.status === "valid";
});
export const instanceIdStore = appDomain.createStore("").on(validateInstanceFx.doneData, payloadProp("instanceId"));

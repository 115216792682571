export const replaceState = (_, s) => s;
export const always = (arg) => () => arg;
export const drop = (..._) => undefined;
import { createEvent } from "effector";
export function asSvelteStore(store) {
    const set = createEvent();
    const newStore = store.map(x => x).on(set, replaceState);
    const svelteStore = Object.assign(Object.assign({}, newStore), { set,
        update(updater) {
            set(updater(newStore.getState()));
        } });
    return svelteStore;
}
export const payloadProp = (key) => (_, s) => s[key];
export const prop = (key) => (s) => s[key];

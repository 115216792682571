import { toast } from "@zerodevx/svelte-toast";
import { createStore, combine, forward } from "effector";
import { replaceState, prop } from "../../utils";
import { createGetRequest, createPostRequest } from "../api";
const defaultSettingsData = {
    hasToken: false,
    locations: [],
};
export const getSettingsData = createGetRequest("settings");
export const settingsStore = createStore(defaultSettingsData).on(getSettingsData.doneData, replaceState);
export const locationsStore = settingsStore.map(prop("locations"));
export const selectedLocationIdStore = settingsStore.map(prop("selectedLocationId"));
export const selectedLocationStore = combine(locationsStore, selectedLocationIdStore, (locations, selectedLocationId) => {
    return locations.find((x) => x.id === selectedLocationId);
});
export const hasTokenStore = settingsStore.map(prop("hasToken"));
export const disconnectFromSquare = createPostRequest("settings/disconnect-from-square");
export const updateSelectedLocation = createPostRequest("settings/update-selected-location");
forward({
    from: [
        updateSelectedLocation.done,
        disconnectFromSquare.done
    ],
    to: getSettingsData,
});
updateSelectedLocation.doneData.watch(() => {
    toast.push("Fulfillment location updated");
});

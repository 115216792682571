<script type="ts" context="module">
  import { SvelteToast } from "@zerodevx/svelte-toast";
  // import ModalOutlet from "./ModalOutlet.svelte";
  import Validator from "./Validator.svelte";
  import Dashboard from "./Dashboard.svelte";
  import Settings from "./Settings.svelte";
  import Utilities from "./Utilities.svelte";
  import GlobalStyle from "./GlobalStyle.svelte";
  import Link from "./Link.svelte";
  import LinkOutlet from "./LinkOutlet.svelte";
</script>

<GlobalStyle />
<SvelteToast options={{}} />
<!-- <ModalOutlet /> -->

<Validator>
  <div class="ui three item menu">
    <Link page="dashboard">Dashboard</Link>
    <Link page="settings">Settings</Link>
    <Link page="utils">Utilities</Link>
  </div>

  <LinkOutlet page="dashboard">
    <Dashboard />
  </LinkOutlet>
  <LinkOutlet page="settings">
    <Settings />
  </LinkOutlet>
  <LinkOutlet page="utils">
    <Utilities />
  </LinkOutlet>
</Validator>

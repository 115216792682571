import { createDomain, forward } from "effector";
export const appDomain = createDomain("appDomain");
export const errorOccured = appDomain.createEvent();
export const globalErrorStore = appDomain
    .createStore([])
    .on(errorOccured, (state, payload) => [...state, payload]);
const globalRequestCountStore = appDomain.createStore(0);
appDomain.onCreateEffect((fx) => {
    globalRequestCountStore.on(fx, (x) => x + 1).on(fx.finally, (x) => x - 1);
});
export const inFlightEffects = globalRequestCountStore.map((state) => state > 0);
globalErrorStore.subscribe((errors) => console.error("Application errors: ", errors));

<script type="ts" context="module">
  import { currentPageChanged, currentPageStore } from "./features/App";
  import type { CurrentPage } from "./features/App";

  function isActive(currentPage: string, selectedPage: string) {
    return currentPage === selectedPage ? "active" : "";
  }
</script>

<script type="ts">
  export let page: CurrentPage;
</script>

<a
  href="#{page}"
  class="{isActive($currentPageStore, page)} item"
  on:click|preventDefault={() => currentPageChanged(page)}><slot></slot></a
>

import App from "./App.svelte";
import { updateInstance, getDashboardData } from "./features";
import { forward } from "effector";
import SocketIoDomain from "./socketio";
const instance = new URLSearchParams(location.search).get("instance");
if (instance) {
    updateInstance(instance);
    const ioDomain = new SocketIoDomain({ path: "/api/socket.io", query: { instance } });
    const inventoryUpdated = ioDomain.attachEvent("inventory-updated");
    forward({ from: inventoryUpdated, to: getDashboardData });
}
const target = document.getElementById("app");
if (target === null) {
    throw new Error("app element is null");
}
const app = new App({ target });
export default app;

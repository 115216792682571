<script context="module" type="ts">
  import { isInstanceValidStore, isInstanceValidatingStore } from "./features";

</script>

{#if $isInstanceValidatingStore}
  <div class="ui row">
    <div class="ui icon message">
      <i class="notched circle loading icon" />
      <div class="content">
        <div class="header">Just one second</div>
        <p>Validating Wix Instance</p>
      </div>
    </div>
  </div>
{:else if $isInstanceValidStore}
  <slot/>
{:else}
  <div class="ui row">
    <div class="ui error message">
      <div class="header">Could not validate Wix Instance</div>
      <ul class="list">
        <li>Please open this app using the Wix dashboard.</li>
      </ul>
    </div>
  </div>
{/if}


import { createEvent, createStore, forward } from "effector";
// export type CurrentPageDictionary = {
//   [key in CurrentPage]: SvelteComponent
// }
// const mappings: CurrentPageDictionary = {
//   dashboard: Dashboard,
//   settings: Settings
// }
export const currentPageChanged = createEvent();
export const currentPageStore = createStore("dashboard");
forward({ from: currentPageChanged, to: currentPageStore });
